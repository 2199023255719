import {
  Directive,
  Optional,
  ComponentRef,
  ViewContainerRef,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { GridLoadingPanelComponent } from '../components';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[cueGridLoading]',
  standalone: true,
})
export class GridLoadingDirective implements AfterViewInit, OnDestroy {
  initialized = false;
  // tslint:disable-next-line: variable-name
  _loading = false;
  // tslint:disable-next-line: variable-name
  _panelComponent: ComponentRef<GridLoadingPanelComponent> = null;

  @Input('cueGridLoading')
  set loading(value: boolean) {
    if (this.initialized) {
      if (this._loading !== value) {
        this.doLoading(value);
      }
    }
    this._loading = value;
  }
  doLoading(value) {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const foundEleme = this.kendoGrid.wrapper.nativeElement
          .getElementsByTagName('kendo-grid-list')[0]
          .getElementsByTagName('app-grid-loading-panel')[0];
        if (value) {
          if (!this._panelComponent) {
            this._panelComponent = this.viewContainer.createComponent(GridLoadingPanelComponent);
          }
          const domElem = this._panelComponent.location.nativeElement;
          if (!foundEleme) {
            this.kendoGrid.wrapper.nativeElement
              .getElementsByTagName('kendo-grid-list')[0]
              .appendChild(domElem);
          }
        } else {
          if (foundEleme) {
            this.kendoGrid.wrapper.nativeElement
              .getElementsByTagName('kendo-grid-list')[0]
              .removeChild(foundEleme);
          }
        }
        this.cdr.markForCheck();
      }, 1);
    });
  }

  constructor(
    @Optional() private kendoGrid: GridComponent,
    private viewContainer: ViewContainerRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {
    if (kendoGrid === undefined) {
      throw Error('cueGridLoading has to be used on kendo-grid element!');
    }
  }

  ngOnDestroy(): void {
    if (this._panelComponent) {
      this._panelComponent.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.doLoading(this._loading);
  }
}
